export type Locale = 'en-de' | 'de-de' | 'en-es' | 'es-es' | 'en-fr' | 'fr-fr';
// | 'en-eu';

export const ENGLISH_LOCALE_IDENTIFIER: Locale = 'en-de';
export const GERMAN_LOCALE_IDENTIFIER: Locale = 'de-de';

// Locales supported to signup on app
export const FULLY_SUPPORTED_LOCALES = [
  'en-de',
  'de-de',
  'en-es',
  'es-es',
  'en-fr',
  'fr-fr',
];

export const LOCALES = [...FULLY_SUPPORTED_LOCALES, 'en-eu'];
